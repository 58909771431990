<Page>
    <Navbar title="All contests" backLink="Recipes" />
    <BlockTitle>About contests</BlockTitle>
</Page>
<script>
    import { Page, Navbar, BlockTitle, Block, Popup, View } from 'framework7-svelte';

    export let f7route;

    const contestId = f7route.params.id;
</script>
