<script>
    import {
        f7,
        Page,
        Navbar,
        Block,
        List,
        ListItem,
        Subnavbar,
        Searchbar,
        Popup,
        View,
        Button,
        NavRight,
        Link,
    } from "framework7-svelte";
    import {
        getUserRecipes,
        selectedRecipe,
        userRecipes,
        getRecipeDetailsFromId,
    } from "../stores/mealPlan";
    import { userDataStore, getUserFirstLastName } from "../stores/userInfo";
    import { showToast } from "../js/generalHelpers";

    export let callbackFunction;
    export let ctaButtonText;

    async function onPageInit() {
        const newRecipes = await getUserRecipes();
        userRecipes.set(newRecipes);
    }

    let chosenRecipe;
    function onRecipeChange(e) {
        const value = e.target.value;
        if (e.target.checked) {
            for(let recipe of $userRecipes){
                if(recipe.id == value){
                    chosenRecipe = recipe;
                    break;
                }

            }
        } else {
            chosenRecipe = null;
        }
    }

    function handleCTA(){
        console.log('handleCTA')
        // Check if the fallback is a function before calling it
        if (typeof callbackFunction === 'function') {
            callbackFunction(chosenRecipe);
        } else {
            console.error('callbackFunction is not a function!');
        }
    }
</script>

<Popup push swipeToClose>
    <View>
        <Page {onPageInit} class="recipe-picker">
            <Navbar title="Select recipe">
                <NavRight>
                    <Link popupClose>Cancel</Link>
                </NavRight>
                <Subnavbar inner={false}>
                    <Searchbar
                        searchContainer=".search-list"
                        searchIn=".item-title, .item-subtitle, .item-text"
                        backdrop={false}
                    />
                </Subnavbar>
            </Navbar>
            <List
                dividersIos
                mediaList
                outlineIos
                strongIos
                class="searchbar-not-found"
            >
                <ListItem title="Nothing found" />
            </List>

            <List
                dividersIos
                mediaList
                outlineIos
                strongIos
                class="search-list"
            >
                {#if $userRecipes && $userRecipes.length > 0}
                    {#each $userRecipes as recipe}
                        {#key recipe.id}
                            <ListItem
                                checkbox
                                checkboxIcon="end"
                                title={recipe.name}
                                name="demo-checkbox"
                                value={recipe.id}
                                class={chosenRecipe != null &&
                                chosenRecipe.id != recipe.id
                                    ? "disabled"
                                    : (chosenRecipe != null ? "selected-sticky" : "")}
                                disabled={chosenRecipe != null &&
                                    chosenRecipe.id != recipe.id}
                                onChange={onRecipeChange}
                                subtitle="By: {recipe.publisher ||
                                    recipe.source ||
                                    (getUserFirstLastName($userDataStore)
                                        .firstName
                                        ? getUserFirstLastName($userDataStore)
                                              .firstName
                                        : '') +
                                        ' ' +
                                        (getUserFirstLastName($userDataStore)
                                            .lastName
                                            ? getUserFirstLastName(
                                                  $userDataStore,
                                              ).lastName
                                            : '')}"
                                text={recipe.description}
                            >
                                <!-- svelte-ignore a11y-missing-attribute -->
                                <div
                                    slot="media"
                                    style="
                                        border-radius: 8px;
                                        width: 80px;
                                        height: 80px;
                                        background-image: url({recipe.image});
                                        background-size: cover;
                                        background-position: center;
                                    "
                                ></div>
                            </ListItem>
                        {/key}
                    {/each}
                {/if}
            </List>

            <div class="static-floating-footer">
                <Button
                    large
                    fill
                    class={chosenRecipe == null ? "disabled" : ""}
                    popupClose
                    on:click={() => {
                        handleCTA()
                    }}>{ctaButtonText ? ctaButtonText : "Submit"}</Button
                >
            </div>
        </Page>
    </View>
</Popup>

<style>
    :global(.recipe-picker .selected-sticky) {
        position: sticky;
        top: 0;
        bottom: 80px;
        z-index: 100;
        background: var(--f7-page-bg-color);
    }
</style>
