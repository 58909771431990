<script>
    import {
        f7,
        Page,
        Navbar,
        BlockTitle,
        Block,
        Popup,
        View,
        Progressbar,
        NavLeft,
        Link,
        NavRight,
        Card,
        CardHeader,
        CardContent,
        CardFooter,
        List,
        ListItem,
        Button,
        Popover,
        Preloader,
        Segmented,
        AccordionContent,
        Sheet,
        PageContent,
    } from "framework7-svelte";
    import { contests } from "../stores/contests";
    import {
        competitionProgress,
        timeRemainingString,
    } from "../js/contestHelpers";
    import { supabase } from "../js/supabase";
    import ExpandableContent from "../components/general/ExpandableContent.svelte";
    import { userDataStore } from "../stores/userInfo";
    import { click } from "dom7";
    import mealPlanStore, { getRecipeDetailsFromId } from "../stores/mealPlan";

    export let f7route;

    const contestId = f7route.params.id;

    let contestDetails = $contests.find((contest) => contest.id === contestId);

    let submissionLoading = false;
    let userSubmissionData = [];
    let submissionData = [];
    let activeSegmentSwitcher = 0;
    let userVotes = [];
    let winnerDetailsLoaded = false; // Flag to check if the details are already loaded
    let selectedRecipeData;

    let submissionStatuses = {
        PENDING_APPROVAL: {
            text: "Pending approval",
            helpText:
                "Your submission is pending approval and is not visible to other users yet. Once approved, it will be visible to voters.",
        },
        APPROVED: {
            text: "Approved",
            helpText:
                "Your submission has been approved and is visible to voters.",
        },
        REJECTED: {
            text: "Rejected",
            helpText:
                "Your submission has been rejected and is not visible to voters.",
        },
    };

    async function onPageInit() {
        console.log("Contest page mounted");
        await getUserContestSubmissions();
        await getAllContestSubmissions();
        await getAllUserVotes();
    }

    // Reactive statement to run getContestWinnerDetails when contestDetails.winners is available
    $: if (contestDetails && contestDetails.winners && !winnerDetailsLoaded) {
        console.log("We have winners", contestDetails.winners);

        getContestWinnerDetails();
    }

    function handleRecipePicked(recipeData) {
        selectedRecipeData = recipeData;

        f7.sheet.open('.recipe-submission-sheet')
    }

    function handleRemoveSubmission(submissionId) {
        console.log("Removing submission", submissionId);
        // Remove the submission
        f7.dialog.confirm(
            `Are you sure you want to remove your submission? This action cannot be undone, any existing votes for your submission will be lost.`,
            async () => {
                await removeRecipeAsContestSubmission(submissionId);
            },
        );
    }

    async function removeRecipeAsContestSubmission(submissionId) {
        // Remove the recipe as a contest submission

        let { data, error } = await supabase
            .from("contest_submissions_v1")
            .delete()
            .eq("id", submissionId);

        if (error) {
            f7.dialog.alert(
                `An error occurred while removing your submission. Please try again later. Error: ${error.message}`,
            );
            return;
        }

        showCustomToast("Your submission has been successfully removed.");

        getUserContestSubmissions();
    }

    async function submitRecipeAsContestSubmission(recipeData) {
        // Submit the recipe as a contest submission

        let { data, error } = await supabase
            .from("contest_submissions_v1")
            .insert([
                {
                    contest_id: contestId,
                    recipe_id: recipeData.id,
                },
            ])
            .select();

        if (error) {
            f7.dialog.alert(
                `An error occurred while submitting your recipe. Have you already submitted this recipe?`,
            );

            return;
        }

        showCustomToast(
            "Your submission has been successfully submitted. It is now pending approval.",
            4000,
        );

        getUserContestSubmissions();
    }

    async function getUserContestSubmissions() {
        // Get all submissions for this contest for this user

        let { data, error } = await supabase
            .from("contest_submissions_v1")
            .select(
                `
            *,
            recipe(
                id,
                name,
                description,
                image
            )`,
            )
            .eq("contest_id", contestId)
            .eq("created_by", $userDataStore.id);

        if (error) {
            f7.dialog.alert(
                `An error occurred while fetching your submissions. Please try again later. Error: ${error.message}`,
            );
            return;
        }

        if (data.length > 0) {
            userSubmissionData = data;
        } else {
            userSubmissionData = [];
        }
    }

    async function getAllContestSubmissions() {
        // Get all submissions for this contest

        let { data, error } = await supabase
            .from("contest_submissions_v1")
            .select(
                `
            *,
            recipe(
                id,
                name,
                description,
                image
            ),
            contest_votes_v1(
                id
            )`,
            )
            .eq("contest_id", contestId)
            .eq("status", "APPROVED");

        if (error) {
            f7.dialog.alert(
                `An error occurred while fetching submissions. Please try again later. Error: ${error.message}`,
            );
            return;
        }

        if (data.length > 0) {
            submissionData = data;
        } else {
            submissionData = [];
        }
    }

    function showCustomToast(message, delay = 2000) {
        let toastBottom = f7.toast.create({
            text: message,
            destroyOnClose: true,
            closeTimeout: delay,
        });

        // Open it
        toastBottom.open();
    }

    async function handleVoteSubmission(submissionId) {
        // Vote for a submission via supabase function insert into contest_votes_v1
        let { data, error } = await supabase.from("contest_votes_v1").insert([
            {
                submission_id: submissionId,
            },
        ]);

        if (error) {
            f7.dialog.alert(
                `An error occurred while voting for this submission. Please try again later. Error: ${error.message}`,
            );
            return;
        }

        showCustomToast("Your vote has been successfully cast.");

        // Refresh the submissions
        await getAllUserVotes();

        // Hacky - but pulling the full submission data again with votes might be costly
        let currentSubmissionCount = parseInt(
            f7.$(`span[data-submission-count-id="${submissionId}"]`)[0]
                .innerText,
        );
        f7.$(`span[data-submission-count-id="${submissionId}"]`)[0].innerText =
            currentSubmissionCount + 1;
    }

    async function handleRemoveVote(submissionId) {
        // Remove a vote via supabase function delete from contest_votes_v1
        let { data, error } = await supabase
            .from("contest_votes_v1")
            .delete()
            .eq("submission_id", submissionId);

        if (error) {
            f7.dialog.alert(
                `An error occurred while removing your vote. Please try again later. Error: ${error.message}`,
            );
            return;
        }

        showCustomToast("Your vote has been successfully removed.");

        // Refresh the submissions
        await getAllUserVotes();

        // Hacky - but pulling the full submission data again with votes might be costly
        let currentSubmissionCount = parseInt(
            f7.$(`span[data-submission-count-id="${submissionId}"]`)[0]
                .innerText,
        );
        f7.$(`span[data-submission-count-id="${submissionId}"]`)[0].innerText =
            currentSubmissionCount - 1;
    }

    async function getAllUserVotes() {
        // Get all votes for this user
        let { data, error } = await supabase
            .from("contest_votes_v1")
            .select(
                `
            *,
            contest_submissions_v1(
                id
            )`,
            )
            .eq("created_by", $userDataStore.id);

        if (error) {
            f7.dialog.alert(
                `An error occurred while fetching your votes. Please try again later. Error: ${error.message}`,
            );
            return;
        }

        if (data.length > 0) {
            // Loop through the data and get the submission ids from the contest_submissions_v1 object
            userVotes = data.map((vote) => vote.contest_submissions_v1.id);
        } else {
            userVotes = [];
        }
    }

    async function getContestWinnerDetails() {
        // Create a local object for modifications
        let updatedWinners = [...contestDetails.winners];

        for (let winner of updatedWinners) {
            let { data, error } = await supabase
                .from("contest_submissions_v1")
                .select(
                    `
                *,
                recipe(
                    id,
                    name,
                    description,
                    image
                )`,
                )
                .eq("id", winner.submission_id);

            if (error) {
                f7.dialog.alert(
                    `An error occurred while fetching the winning recipe details. Please try again later. Error: ${error.message}`,
                );
                return;
            }

            if (data.length > 0) {
                // Add recipe details to the local winner object
                winner.recipe = data[0].recipe;
            } else {
                winner.recipe = null;
            }
        }

        // Now that all updates are done, assign the updated winners back to contestDetails
        contestDetails = { ...contestDetails, winners: updatedWinners };

        // Set the flag to true to avoid re-running the function
        winnerDetailsLoaded = true;
    }
</script>

<Popup push swipeToClose class="contest-details-popup">
    <View>
        <Page class="contest-page" {onPageInit}>
            <Navbar transparent>
                <NavRight>
                    <Link popupClose>
                        <i
                            class="fa fa-xmark navbar-icon"
                            style="padding-right: 10px"
                        ></i>
                    </Link>
                </NavRight>
            </Navbar>
            <div>
                <!-- svelte-ignore a11y-missing-attribute -->
                <img
                    src={contestDetails.main_banner_image}
                    style="width: 100%;"
                />
            </div>
            <div
                style="padding: 10px 20px 10px 20px; font-size: 24px; font-weight: 700;"
            >
                {contestDetails.title}
            </div>
            <Card outline style="margin-top: 0px;">
                <CardContent>
                    {#if new Date(contestDetails.end_datetime) > new Date()}
                        <Progressbar
                            progress={competitionProgress(
                                $contests[0].start_datetime,
                                $contests[0].end_datetime,
                            )}
                        />
                    {/if}
                    <div style="padding-top: 5px; font-weight: 700;">
                        {timeRemainingString(
                            $contests[0].start_datetime,
                            $contests[0].end_datetime,
                        )}
                    </div>
                </CardContent>
            </Card>
            {#if contestDetails.winners}
                {#each contestDetails.winners as winner}
                    {#if winner.recipe}
                        <Card outline>
                            <CardContent>
                                <List mediaList>
                                    <ListItem>
                                        <div slot="title">
                                            {winner.place_title}
                                        </div>
                                        <div slot="subtitle">
                                            {winner.prize_text}
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <!-- svelte-ignore missing-declaration -->
                                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                                        <div
                                            slot="title"
                                            on:click={() => {
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                winner.recipe
                                                                    .id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {winner.recipe.name}
                                        </div>
                                        <!-- svelte-ignore missing-declaration -->
                                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                                        <div
                                            slot="subtitle"
                                            on:click={() => {
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                winner.recipe
                                                                    .id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {winner.recipe.description}
                                        </div>
                                        <!-- svelte-ignore a11y-missing-attribute -->
                                        <!-- svelte-ignore missing-declaration -->
                                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                                        <img
                                            slot="media"
                                            style="
                                    border-radius: 8px;
                                    width: 50px;
                                    height: 50px;
                                    background-image: url({winner.recipe
                                                .image});
                                    background-size: cover;
                                    background-position: center center;
                                "
                                            on:click={() => {
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                winner.recipe
                                                                    .id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    {/if}
                {/each}
            {/if}

            <Block style="margin-top: 20px; margin-bottom: 20px;">
                <ExpandableContent>
                    {contestDetails.description}
                </ExpandableContent>
            </Block>
            <Block style="margin-top: 10px; margin-bottom: 10px;">
                <Segmented strong tag="p">
                    <Button
                        active={activeSegmentSwitcher === 0}
                        onClick={() => (activeSegmentSwitcher = 0)}
                        >All submissions</Button
                    >
                    <Button
                        active={activeSegmentSwitcher === 1}
                        onClick={() => (activeSegmentSwitcher = 1)}
                        >Your submissions</Button
                    >
                </Segmented>
            </Block>
            {#if activeSegmentSwitcher === 0}
                {#if submissionData.length > 0}
                    {#each submissionData as submission}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <Card outline data-submission-id={submission.id}>
                            <CardContent>
                                <List mediaList>
                                    <ListItem>
                                        <div
                                            slot="title"
                                            on:click={() => {
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                submission
                                                                    .recipe.id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {submission.recipe.name}
                                        </div>
                                        <div
                                            slot="subtitle"
                                            on:click={() => {
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                submission
                                                                    .recipe.id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {submission.recipe.description}
                                        </div>
                                        <!-- svelte-ignore a11y-missing-attribute -->
                                        <img
                                            slot="media"
                                            style="
                                        border-radius: 8px;
                                        width: 50px;
                                        height: 50px;
                                        background-image: url({submission.recipe
                                                .image});
                                        background-size: cover;
                                        background-position: center center;
                                    "
                                            on:click={() => {
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                submission
                                                                    .recipe.id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </CardContent>
                            <CardFooter>
                                <div>
                                    <span
                                        style="font-size: 16px;"
                                        data-submission-count-id={submission.id}
                                        >{submission.contest_votes_v1
                                            .length}</span
                                    >
                                    <span style="font-size: 16px;">votes</span>
                                </div>
                                <div
                                    style="display: flex; align-items: center;"
                                >
                                    {#if userVotes.includes(submission.id)}
                                        <Button
                                            outline
                                            round
                                            style="text-transform: none;"
                                            on:click={() => {
                                                handleRemoveVote(submission.id);
                                            }}>Voted</Button
                                        >
                                    {:else if new Date(contestDetails.end_datetime) > new Date()}
                                        <Button
                                            fill
                                            round
                                            style="text-transform: none;"
                                            on:click={() => {
                                                handleVoteSubmission(
                                                    submission.id,
                                                );
                                            }}>+1 Vote</Button
                                        >
                                    {/if}
                                </div>
                            </CardFooter>
                        </Card>
                    {/each}
                {:else}
                    <div
                        style="width: 100%; text-align: center; margin-top: 20px;"
                    >
                        No submissions yet.
                    </div>
                {/if}
            {:else if activeSegmentSwitcher === 1}
                <!-- User's own submissions -->
                {#if userSubmissionData.length > 0}
                    {#each userSubmissionData as submission}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <Card outline>
                            <CardContent>
                                <List mediaList>
                                    <ListItem>
                                        <div
                                            slot="title"
                                            on:click={() => {
                                                console.log(
                                                    "Clicked on submission",
                                                    submission,
                                                );
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                submission
                                                                    .recipe.id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {submission.recipe.name}
                                        </div>
                                        <div
                                            slot="subtitle"
                                            on:click={() => {
                                                console.log(
                                                    "Clicked on submission",
                                                    submission,
                                                );
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                submission
                                                                    .recipe.id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {submission.recipe.description}
                                        </div>
                                        <!-- svelte-ignore a11y-missing-attribute -->
                                        <img
                                            slot="media"
                                            style="
                                        border-radius: 8px;
                                        width: 50px;
                                        height: 50px;
                                        background-image: url({submission.recipe
                                                .image});
                                        background-size: cover;
                                        background-position: center center;
                                    "
                                            on:click={() => {
                                                console.log(
                                                    "Clicked on submission",
                                                    submission,
                                                );
                                                f7.views.current.router.navigate(
                                                    `/recipe/`,
                                                    {
                                                        props: {
                                                            recipeId:
                                                                submission
                                                                    .recipe.id,
                                                            addToMealPlan: true,
                                                        },
                                                    },
                                                );
                                            }}
                                        />
                                        <div slot="after">
                                            <Button
                                                popoverOpen={`.popover-submission-more-${submission.id}`}
                                            >
                                                <i class="f7-icons">ellipsis</i>
                                            </Button>
                                        </div>
                                    </ListItem>
                                </List>
                            </CardContent>
                            <CardFooter>
                                <div>
                                    Status: {submissionStatuses[
                                        submission.status
                                    ].text}
                                    <Link
                                        popoverOpen={`.popover-submission-status-info-${submission.id}`}
                                    >
                                        <i
                                            class="f7-icons"
                                            style="font-size: 16px; color: #cfcfcf;"
                                            >info_circle_fill</i
                                        >
                                    </Link>
                                </div>
                            </CardFooter>
                        </Card>
                        <!-- TODO fix that these are replacing the previous one and duplucate instances are being created with -->
                        <Popover
                            class={`popover-submission-status-info-${submission.id}`}
                        >
                            <p style="padding: 10px;">
                                {submissionStatuses[submission.status].helpText}
                            </p>
                        </Popover>
                        <Popover
                            class={`popover-submission-more-${submission.id}`}
                        >
                            <List>
                                <ListItem
                                    link="#"
                                    popoverClose
                                    title="Remove submission"
                                    on:click={handleRemoveSubmission(
                                        submission.id,
                                    )}
                                />
                            </List>
                        </Popover>
                    {/each}
                {/if}

                {#if new Date(contestDetails.end_datetime) > new Date()}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <div
                        on:click={() => {
                            f7.views.current.router.navigate(
                                `/recipe-picker/`,
                                {
                                    props: {
                                        collectionType: "USER_CREATED",
                                        callbackFunction: handleRecipePicked,
                                        ctaButtonText: "Add to submission",
                                    },
                                },
                            );
                        }}
                        style="width: 100%;"
                    >
                        <Card class="add-submission-card">
                            <CardContent>
                                {#if submissionLoading}
                                    <Preloader size={42} />
                                {:else}
                                    Add recipe submission
                                {/if}
                            </CardContent>
                        </Card>
                    </div>
                {/if}
            {/if}
            <Sheet
                class="recipe-submission-sheet"
                style="height: auto"
                swipeToClose
                backdrop={true}
            >
                <div class="swipe-handler" />
                <PageContent>
                    <BlockTitle large>Submission</BlockTitle>
                    <Block>
                        <p>
                            Would you like to add "{selectedRecipeData && selectedRecipeData.name ? selectedRecipeData.name : ""}" as a submission to the contest?
                        </p>
                        <p>
                            By submitting this recipe, you've read, agreed and abide by the contest rules and guidelines.
                        </p>
                    </Block>
                    <Block>
                        <Button large fill on:click={async () => {
                            f7.sheet.close('.recipe-submission-sheet');

                            submissionLoading = true;
                            await submitRecipeAsContestSubmission(selectedRecipeData);
                            submissionLoading = false;
                        }}>Submit recipe</Button>
                    </Block>
                </PageContent>
            </Sheet>
        </Page>
    </View>
</Popup>

<style>
    .navbar-icon {
        font-size: 24px;
        transition: color 0.3s ease;
        color: #fff;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    }

    :global(.navbar-transparent-visible .navbar-icon) {
        color: var(--f7-theme-color) !important;
        text-shadow: none !important;
    }

    :global(.contest-page .page-content) {
        padding-top: 0;
    }

    :global(.add-submission-card) {
        border-style: dashed;
        border-width: 3px;
        border-color: #cfcfcf;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        color: #cfcfcf;
    }
</style>
