<script>
    import { onMount, tick } from 'svelte';

    let isExpanded = false;
    let contentHeight = 0;
    let maxHeight = 0; // Max height for 3 lines
    let lineHeight = 0; // Line height of the content
    let showButton = false; // Determines whether to show the "Show More" button

    let content;
    export let textContent = ""; // Allow dynamic content to be passed in as a prop

    // Recalculate the heights and check for overflow whenever the content changes
    $: if (content) {
        recalculateHeight();
    }

    async function recalculateHeight() {
        await tick(); // Ensure DOM updates are complete

        const computedStyle = getComputedStyle(content);
        lineHeight = parseFloat(computedStyle.lineHeight); // Get the line height
        contentHeight = content.scrollHeight; // Get the total height of the content
        maxHeight = lineHeight * 3; // Calculate max height for 3 lines

        // Check if content height exceeds 3 lines
        const numberOfLines = Math.floor(contentHeight / lineHeight);
        showButton = numberOfLines > 3;
    }

    function toggle() {
        isExpanded = !isExpanded;
    }
</script>

<style>
    .expandable-content {
        overflow: hidden;
        max-height: var(--max-height);
        transition: max-height 0.3s ease-in-out;
        position: relative;
    }

    .expandable-content.truncated {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
    }

    :global(.expanded) {
        max-height: none;
        display: block; /* Reset display when expanded */
        -webkit-line-clamp: unset; /* Reset line clamp when expanded */
    }
</style>

<div>
    <div 
        bind:this={content} 
        class="expandable-content {isExpanded ? '' : 'truncated'}"
        style="max-height: {isExpanded ? 'none' : maxHeight + 'px'};">
        {#if textContent}
            {@html textContent}  <!-- Display dynamic content passed in as a variable -->
        {:else}
            <slot /> <!-- Fallback to slot for static content -->
        {/if}
    </div>
    {#if showButton}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <a on:click={toggle}>
            {isExpanded ? 'Show Less' : 'Show More'}
        </a>
    {/if}
</div>
