export function timeRemainingString(startDate, endDate) {
    // Check if startDate and endDate are strings with timezone info and convert them
    if (typeof startDate === "string" && startDate.includes("+")) {
        startDate = new Date(startDate);
    }
    if (typeof endDate === "string" && endDate.includes("+")) {
        endDate = new Date(endDate);
    }

    // If not Date objects, convert them
    if (!(startDate instanceof Date)) {
        startDate = new Date(startDate);
    }
    if (!(endDate instanceof Date)) {
        endDate = new Date(endDate);
    }

    // Get the current date and time
    const now = new Date();

    // Check if the current time is before the start date or after the end date
    if (now < startDate) {
        return "This contest has not yet started.";
    } else if (now >= endDate) {
        return "This contest has ended.";
    } else {
        // Calculate the difference in milliseconds
        const timeDiff = endDate - now;

        // Calculate the remaining time in weeks, days, hours, and minutes
        const weeksRemaining = Math.floor(
            timeDiff / (1000 * 60 * 60 * 24 * 7),
        );
        const daysRemaining = Math.floor(
            (timeDiff % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24),
        );
        const hoursRemaining = Math.floor(
            (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutesRemaining = Math.floor(
            (timeDiff % (1000 * 60 * 60)) / (1000 * 60),
        );

        // Helper function to pluralize time units
        const pluralize = (unit, count) =>
            count === 1 ? unit : `${unit}s`;

        // Build the output string based on the remaining time
        let result = "";
        if (weeksRemaining > 0) {
            result += `${weeksRemaining} ${pluralize("week", weeksRemaining)} `;
        }
        if (daysRemaining > 0) {
            if (weeksRemaining > 0) {
                result += `and ${daysRemaining} ${pluralize("day", daysRemaining)} `;
            } else {
                result += `${daysRemaining} ${pluralize("day", daysRemaining)} `;
            }
        }
        if (hoursRemaining > 0 && weeksRemaining === 0) {
            if (daysRemaining > 0) {
                result += `and ${hoursRemaining} ${pluralize("hour", hoursRemaining)} `;
            } else {
                result += `${hoursRemaining} ${pluralize("hour", hoursRemaining)} `;
            }
        }
        if (
            minutesRemaining > 0 &&
            weeksRemaining === 0 &&
            daysRemaining === 0
        ) {
            if (hoursRemaining > 0) {
                result += `and ${minutesRemaining} ${pluralize("minute", minutesRemaining)} `;
            } else {
                result += `${minutesRemaining} ${pluralize("minute", minutesRemaining)} `;
            }
        }

        // Trim and return the final result string
        return "Ends in " + result.trim();
    }
}

export function competitionProgress(startDate, endDate) {
    // Check if startDate and endDate are strings with timezone info and convert them
    if (typeof startDate === "string" && startDate.includes("+")) {
        startDate = new Date(startDate);
    }
    if (typeof endDate === "string" && endDate.includes("+")) {
        endDate = new Date(endDate);
    }

    // If not Date objects, convert them
    if (!(startDate instanceof Date)) {
        startDate = new Date(startDate);
    }
    if (!(endDate instanceof Date)) {
        endDate = new Date(endDate);
    }

    // Get the current date and time
    const now = new Date();

    // Calculate the total duration and the elapsed time
    const totalDuration = endDate - startDate;
    const elapsedTime = now - startDate;

    // Calculate the percentage remaining
    const percentageElapsed = (elapsedTime / totalDuration) * 100;

    // Ensure the percentage is between 1 and 100
    const percentageRemaining =
        100 - Math.min(Math.max(percentageElapsed, 0), 100);

    // Return the rounded percentage remaining
    return Math.round(percentageRemaining);
}