  <Page name="profile" on:pageTabShow={pageView}>
  <!-- Top Navbar -->
  <Navbar transparent sliding={false}>
    <NavTitle sliding>Profile</NavTitle>
  </Navbar>
  <div style="display: flex; align-items: center;">
    <div class="page-title">
      Profile
    </div>
    <div style="margin-right: 10px;;">
      <Link on:click={logoutUser}>Logout</Link>
    </div>
  </div>
  
  <Block style="margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;">
    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="item-media profile-image" on:click={handleImageChange}>
      <CameraIcon size="28" class={hasProfilePic ? 'hidden' : ''}/>
    </div>
    <input class="hidden" id="profile-image-upload" on:change={uploadImage} type="file" accept=".png, .jpg"/>
  </Block>
  <div class="user-info-container">
    {#if firstName}
      <div class="user-name">
        {firstName} {lastName ? lastName : ''}
      </div>
    {/if}
    <div class="user-email">
      {$userDataStore && $userDataStore.user_metadata ? $userDataStore.user_metadata.email : ''}
    </div>
    <div class="edit-profile-button">
      <Button round style="text-transform:none;" popoverOpen=".popover-edit-profile">Edit Profile</Button>
    </div>
  </div>
  <BlockTitle>Your preferences</BlockTitle>
  <List dividers outline strong inset>
    <ListItem title="Update preferences" link="/user-preferences/" />
  </List>

  <!-- Experimantal section - disabled 
  <List strong outlineIos dividersIos insetMd accordionList>
    <ListItem accordionItem title="Experimental">
      <AccordionContent>
        <List strongIos outlineIos dividersIos>
          <ListItem
            checkbox
            name="experimental-checkbox"
            value="ZestyGPT"
            title="ZestyGPT"
            onChange={handleExperimentalToggleChange}
            checked={$featureTogglesStore.includes('ZestyGPT')}
          ></ListItem>
          <ListItem
            checkbox
            name="experimental-checkbox"
            value="ModifyUsingAI"
            title="ModifyUsingAI"
            onChange={handleExperimentalToggleChange}
            checked={$featureTogglesStore.includes('ModifyUsingAI')}
          ></ListItem>
          <ListItem
            checkbox
            name="experimental-checkbox"
            value="DisableAIFAB"
            title="DisableAIFAB"
            onChange={handleExperimentalToggleChange}
            checked={$featureTogglesStore.includes('DisableAIFAB')}
          ></ListItem>
          <ListItem
            title="Open congrats modal"
            on:click={() => (window.Framework7ComponentsApp.f7.sheet.open('.onboarding-modal-add-recipe'))}
          ></ListItem>
          <ListItem
            title="Open onboarding modal"
            on:click={() => (f7.views.current.router.navigate('/onboarding/'))}
          ></ListItem>
          <ListItem
            title="Reset first meal added onboarding"
            on:click={() => (localStorage.removeItem('addedFirstMeal'))}
          ></ListItem>
          <ListItem
            title="Clear meal plan data"
            on:click={() => (clearMealPlanData())}
          ></ListItem>
          <ListItem
            title="Open preferences wizard."
            on:click={() => (f7.views.current.router.navigate('/user-preference-questions/'))}
          ></ListItem>
        </List>
      </AccordionContent>
    </ListItem>
  </List>
  -->

  <Block>
    <Button large fill on:click={() => {
      const TARGET = process.env.TARGET || "web"; // Default to Web
      if(TARGET.toLocaleLowerCase() === 'cordova') {
        window.cordova.InAppBrowser.open('https://forms.gle/KvmSMi3TbiabWfG99', '_system'); // Open app in System's default browser rather than in app
      } else {
        window.open('https://forms.gle/KvmSMi3TbiabWfG99' , '_blank');
      }
    }}>Leave feedback!</Button>
  </Block>
  <Block>
    <div style="text-align: center;">
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" on:click={() => { clickLink('https://zestyplan.com/zestyplan-privacy-policy/')}}>Privacy Policy</a>
    </div>
  </Block>
  <Popover class="popover-edit-profile" verticalPosition="bottom">
    <List>
        <ListItem link="#" class="popover-close" title="Change Name" on:click={() => (f7.views.current.router.navigate('/update-profile-name/'))}/>
        <ListItem link="#" class="popover-close" title="Change email" on:click={() => (f7.views.current.router.navigate('/update-profile-email/'))} />
        <ListItem link="#" class="popover-close" title="Reset password" on:click={() => (f7.views.current.router.navigate('/password-reset/'))} />
        <ListItem link="#" class="popover-close" title="Delete account" on:click={() => {
          const TARGET = process.env.TARGET || "web"; // Default to Web
          if(TARGET.toLocaleLowerCase() === 'cordova') {
            window.cordova.InAppBrowser.open('https://forms.gle/27iq5Ey5UsvgUto2A', '_system'); // Open app in System's default browser rather than in app
          } else {
            window.open('https://forms.gle/27iq5Ey5UsvgUto2A' , '_blank');
          }
        }} />
    </List>
  </Popover>
</Page>
<script>
  import {
    f7,
    Page,
    Navbar,
    List,
    ListInput,
    ListItem,
    Toggle,
    BlockTitle,
    Button,
    Range,
    Block,
    NavTitle,
    NavRight,
    Link,
    NavTitleLarge,
    AccordionContent,
    Input,
    Popover
  } from 'framework7-svelte';
  import { userDataStore, updateUserData, getUserFirstLastName } from "../stores/userInfo"
  import {featureTogglesStore, updateToggle} from "../stores/featureToggles"
  import { logoutUser, uploadProfileImage, getProfileImage } from "../js/supabase"
  import { showToast } from "../js/generalHelpers"
  import '../css/profile.css';
  import { CameraIcon } from 'svelte-feather-icons'

  let firstName = getUserFirstLastName($userDataStore).firstName;
  let lastName =  getUserFirstLastName($userDataStore).lastName;
  let hasProfilePic = false;

  function pageView() {
      if(window.TapticEngine){
          window.TapticEngine.impact({
              style: "light" // light | medium | heavy | rigid (iOS 13+) | soft (iOS 13+)
          });
      }
  }

  function handleImageChange() {
        // Trigger hidden input click for image upload
        // note will only trigger for new images, to avoid duplicates
        document.getElementById('profile-image-upload').click();
    }
    async function uploadImage() {
        const file = document.getElementById('profile-image-upload').files[0];
        if (file) {
           const path = await uploadProfileImage(file);
           hasProfilePic = true;
           document.getElementsByClassName('profile-image')[0].style.backgroundImage = `url(${path})`;
        } else {
            f7.dialog.alert('There was an error getting the image.');
        }
    }

  $:onUserStoreChange($userDataStore)
  function onUserStoreChange(value) {
    if (value.first_name) {
      firstName = value.first_name;
    }
    if(value.last_name) {
      lastName = value.last_name;
    }

    if(value.avatar_url) {
      const path = getProfileImage(value.avatar_url)
      .then((path) => {
        hasProfilePic = true;
        document.getElementsByClassName('profile-image')[0].style.backgroundImage = `url(${path})`;
      });
    } else {
      hasProfilePic = false;
      if(document.getElementsByClassName('profile-image').length > 0) {
        document.getElementsByClassName('profile-image')[0].style = '';
      }
    }
  }

  function clearMealPlanData(){
    localStorage.removeItem('mealPlanData')
    window.location.reload()
  }

  function handleExperimentalToggleChange(event){
    const experimentName = event.currentTarget.value;

    if(event.currentTarget.checked){
      // Enabling the toggle
      updateToggle({
        toggleName : experimentName,
        enabled : true
      })
    } else {
      // Disabling the toggle
      updateToggle({
        toggleName : experimentName,
        enabled : false
      })
    }
  }

  function clickLink(target_url) {
    if(window.cordova) {
      cordova.InAppBrowser.open(target_url, '_system'); // Open app in System's default browser rather than in app
    } else {
      window.open(target_url , '_blank');
    }
  }

</script>
