import { writable } from 'svelte/store';
import { supabase } from "../js/supabase"

export const contests = writable(null);

export const getContests = async function(){

    let currentValue;
    contests.subscribe(value=>{
        currentValue = value;
    })();

    if(currentValue && currentValue.length > 0){
        return currentValue;
    }

    // If not already loaded, fetch contests
    try {
        const { data, error } = await supabase
            .from('contest_v1')
            .select('*')
            .order('end_datetime', { ascending: true });
    
        if (error) {
            console.error('Error fetching contests:', error.message);
            debugger;
        } else {
            console.log('contests:', data);
            contests.set(data);
        }
    } catch (err) {
        console.error('Unexpected error getting contests:', err);
        debugger;
    }
}
