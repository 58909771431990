<script>
    import {
        f7,
        Block,
        Progressbar,
        Link,
        Card,
        CardHeader,
        CardFooter,
        CardContent,
    } from "framework7-svelte";
    import { onMount, onDestroy } from "svelte";
    import { supabase } from "../../js/supabase";
    import { contests, getContests } from "../../stores/contests";
    import { competitionProgress, timeRemainingString } from "../../js/contestHelpers";

    onMount(async () => {
        console.log("Competition banner mounted");

        setTimeout(function () {
            getContests();
        }, 300);
    });

    function handleCompetitionClick(contestId) {
        setTimeout(function () {
            f7.views.current.router.navigate(`/contest/${contestId}/`);
        }, 150);
    }

    
</script>

{#if !$contests}
    <Block
        class="skeleton-text skeleton-effect-wave"
        style="margin-bottom: -10px; margin-top: -10px; display: flex;"
    >
        <div
            class="skeleton-block"
            style="height: 150px; border-radius: 8px;"
        ></div>
    </Block>
{:else if $contests && $contests.length > 0}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
        on:click={handleCompetitionClick($contests[0].id)}
        class="recessedOnTouch"
    >
        <Card outlineMd raised>
            <CardHeader valign="bottom" style="padding: 0px;">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-missing-attribute -->
                <img
                    src={$contests[0].promo_banner_image}
                    style="width: 100%;"
                />
            </CardHeader>
            <CardContent style="padding-bottom: 10px;">
                <h3 style="margin: 0;">{$contests[0].title}</h3>
            </CardContent>
            <CardFooter>
                <div style="width: 100%;">
                    <Progressbar
                        progress={competitionProgress(
                            $contests[0].start_datetime,
                            $contests[0].end_datetime,
                        )}
                    />
                    <div style="padding-top: 5px;">
                        {timeRemainingString(
                            $contests[0].start_datetime,
                            $contests[0].end_datetime,
                        )}
                    </div>
                </div>
            </CardFooter>
        </Card>
    </div>
{/if}

<style>
    :global(.card-header-pic) {
        height: 40vw;
        background-size: cover;
        background-position: center;
        color: #fff;
    }

    .recessedOnTouch {
        transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
        opacity: 1; /* Initial opacity */
    }

    .recessedOnTouch:active {
        transform: scale(0.95);
        opacity: 0.8; /* Dimming effect */
    }
</style>
